import React from "react"
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from "gatsby";

const Impressum: React.FC<{}> = () => {
  return (
    <Layout>
      <Helmet>
        <title>Impressum</title>
      </Helmet>

      <h1 className="mb-4">Impressum</h1>
      <h2>Managing Director</h2>
      <img style={{maxWidth: "250px"}} src="https://cloud.riksolo.com/apps/files_sharing/publicpreview/BpmxeW45DLQe675?file=/&fileId=7809203&x=800&y=600&a=true" />
      This Little Guy <br />
      <h3>Address</h3>
      Some Forest Somewhere <br />
      Third stump on the right <br />
      <h3>Contact</h3>
      I don't know man who knows if he even has a computer
    </Layout>
  );
};

export default Impressum;
